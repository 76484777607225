<template>
  <div>
    <!-- ADD NEW -->
    <vs-button
      
      @click="newBanner"
      class="
        btn-add-new
        p-3
        mb-4
        mr-4
        rounded-lg
        cursor-pointer
        flex
        items-center
        justify-center
      "
    >
      <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
      <span class="ml-2 text-lg">اضافة إعلان</span>
    </vs-button>
   
    <div class="vx-row">
      <div
        v-for="banner in banners"
        :key="banner.id"
        class="vx-col w-full vx-card card"
      >
        <div class="banner-block w-full">
          <h1 class="banner-type">إعلان {{ getTypeOfBanner(banner.type) }}</h1>
          <img class="banner-image" :src="banner.image" alt="Banner-Image" />

          <div class="flex justify-around flex-wrap mb-10">
            <vs-button
              v-if="!(banners.length == 1)"
              class="mt-4"
              type="border"
              color="#D32F2F"
              @click="deleteBanner(banner.id)"
              >حذف</vs-button
            >
            <div v-if="banners.length < 2">
              <div class="text-primary text-xl">
                <span class="text-warning">ملاحظة:</span> لا يمكنك حذف كل
                الإعلانات يجب ترك إعلان واحد على الأقل.
              </div>
            </div>
            <!-- <router-link :to="{ name: 'edit-banner', params: { id: banner.id } }">
              <vs-button
                class="mt-4 mr-2 shadow-lg primary"
                type="gradient"
                gradient-color-secondary="#CE9FFC"
                >تعديل</vs-button
              >
            </router-link> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      banners: [],
    };
  },
  methods: {
    ...mapActions("banners", ["fetchBanners", "deleteBanner"]),
    getTypeOfBanner(type) {
      switch (type) {
        case "brand":
          type = "علامة تجارية";
          break;
        case "product":
          type = "منتج";
          break;
        case "store":
          type = "متجر";
          break;
        case "not-clickable":
          type = "تجاري";
          break;
      }
      return type;
    },

    newBanner() {
      this.$router.push({
        name: "new-banner",
      });
    },
  },
  created() {
    this.$vs.loading();
    this.fetchBanners()
      .then((response) => {
        this.banners = response;
        this.$vs.loading.close();
      })
      .catch((err) => {
        throw err;
      });
  },
};
</script>

<style lang="scss" scoped>
.banner-type {
  margin: 20px;
  text-align: center;
}
.banner-image {
  border-radius: 20px;
  padding: 10px;
  width: 100%;
  height: 450px;
}
.head-line {
  font-size: 3em;
  text-align: center;
  margin: 10px;
  margin-bottom: 30px;
}
.card {
  margin: 1em;
}
</style>
